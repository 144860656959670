form {
    .fields {
        .ideofi {
            color: $st-black_01;
            font-weight: 400;
            line-height: normal;
            background-color: $st-gold_02;
            padding: 1rem !important;
            .custom-file-adip {
                display: flex;
                align-items: center;
                justify-content: center !important;
                background-color: $st-gold_03;
                margin: 0 0 0.5rem;
                padding: 1rem !important;
                border-radius: 6px;
                .custom-file-label-adip {
                    margin: 0;
                    cursor: pointer;
                    p {
                        font-size: 0.9375rem;
                        margin: 0;
                    }
                }
                .cont-actions {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    p {
                        margin: 0;
                        small {
                            display: block;
                            width: 100%;
                            max-width: 120px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            color: $st-green_01;
                            font-size: 0.9rem;
                            font-weight: 600;
                        }
                    }
                }
            }
            .nosubir {
                font-size: 0.75rem;
            }
        }
    }
}
