.tit {
	font-family: Inter;
	font-size: 25px;
	font-weight: 700;
	letter-spacing: 0em;
	text-align: left;
	color: #BC955C;
}

.subtit {
	font-size: 20px;
}

.nomdia {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #FFFFFF;
    background: #DDC9A3;
    text-align: center;
    border-radius: 5px;
}

.tittabla {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 13px;
	color: #BC955C;
}

.horario {
            text-align: center;
}

.w20 {
        width: 20%;
}

.w10 {
        width: 10%;
}

.w30 {
        width: 30%;
}

.w60 {
        width: 60%;
}

.horariodea {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #6F7271;
}

.horarioselect {
    background: #FFFFFF;
    border: 0.92228px solid #A0AEC0;
    border-right: none;
    border-radius: 3.68912px;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
}

    .sincaret{
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

.checkbox_cdmx + label:before {
  content: "";
  display: block;
  width: 22px;
  height: 22px;
  margin-right: 14px;
  position: absolute;
  top: -7px;
  left: 10px;
  border: 1px solid #BC955C;
  background-color: #fff;
  border-radius: 5px;
}

.checkbox_cdmx + label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  margin-right: 15px;
  font-size: 13px;
}

.checkbox_cdmx:disabled + label:before {
  content: "";
  display: block;
  width: 22px;
  height: 22px;
  margin-right: 14px;
  position: absolute;
  top: -7px;
  left: 10px;
  border: 1px solid #BC955C;
  background-color: $st-gray_08;
  border-radius: 5px;
}


.checkbox_cdmx {
  display: none !important;
  *display: inline;
}

.checkbox_cdmx:checked + label:after {
    content: "\2714";
    font-size: 20px;
    line-height: 20px;
    color: #fff;
    display: block;
    position: absolute;
    top: -6px;
    left: 11px;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background-color: #BC955C;
}

