#bandeja {
    .fields {
        border-radius: 10px;
        .btn {
            display: inline-flex;
            height: auto;
            font-size: $fs-15;
            font-weight: 700;
            letter-spacing: 0.035rem;
            line-height: $fs-15;
            padding: 0.75rem 1.25rem;
            border: 2px solid transparent;
            border-radius: 6px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &.action {
                color: $st-green_01;
                background-color: white;
                border-color: $st-green_01;
                &:hover {
                    color: $st-gold_01;
                    border-color: $st-gold_01;
                    img {
                        filter: grayscale(1);
                    }
                }
            }
            &.register {
                width: 100%;
                max-width: 250px;
                color: white;
                background-color: $st-green_01;
                &:hover {
                    background-color: $st-green_01h;
                }
            }
            img {
                max-width: 12px;
                margin: 0 0.5rem 0 0;
            }
        }
    }
    table {
        thead {
            background-color: $st-gold_01;
            color: white;
            th {
                width: calc((100% - 35px) / 8);
                max-width: 100%;
                vertical-align: middle;
                font-size: $fs-14;
                font-weight: 700;
                line-height: normal;
                text-align: center;
                border: none;
                &.number {
                    width: 35px;
                    font-weight: 800;
                }
            }
        }
        tbody {
            tr {
                th {
                    font-size: $fs-14;
                    font-weight: 400;
                    text-align: center;
                    line-height: normal;
                    vertical-align: middle;
                    .st-btn {
                        width: 100%;
                        font-size: $fs-13;
                        line-height: $fs-13;
                        padding: 0.5rem 1rem;
                    }
                }
                &:nth-child(odd) {
                    background-color: $st-gold_04;
                }
                &:hover {
                    background-color: $st-gray_04;
                }
            }
        }
    }
}
