#modalNoRecuperables,
#modalFechas,
#modalFamiliar {
    h1 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 900;
        font-size: 1.7em;
        line-height: 34px;
        text-align: center;
        color: $verde03;
    }
    h2 {
        font-weight: 700;
        font-size: 1.2em;
        color: $verde03;
    }
    .modal-content {
        border-color: $verde03;
        border-width: 3px;
    }
}
