*:disabled {
    opacity: 1;
    pointer-events: none;
    background-color: $st-gray_08;
}

.dirty {
    color: $st-blue_03 !important;
    background-color: $st-blue_01;
    border-color: $st-blue_02 !important;
}

.pointer {
    cursor: pointer;
}

.mw-220 {
    max-width: 220px !important;
}

.mw-250 {
    max-width: 250px !important;
}

// Errors

span.error {
    display: block;
    color: $st-red_06;
    text-align: center;
    background-color: $st-red_01;
    margin: 0.25rem 0 0;
    padding: 0.15rem;
    border: 2px solid $st-red_02;
    border-radius: 4px;
}

// Steps

.steps {
    background-color: white;
    padding: 1.5rem;
    border-radius: 10px;
    .step {
        display: flex;
        align-items: center;
        justify-content: start;
        cursor: pointer;
        // Pendiente
        .circle {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 34px;
            height: 34px;
            padding: 4px;
            border-radius: 50%;
            border: 4px solid $st-gray_01;
            div {
                width: 100%;
                height: 100%;
                background-color: $st-gray_06;
                border-radius: 50%;
            }
        }

        span {
            color: $st-gray_06;
            font-size: $fs-14;
            line-height: $fs-14;
            font-weight: 700;
            margin: 0 0 0 5px;
        }
        // Hover
        &:hover {
            .circle {
                border-color: $st-gold_03;
                div {
                    background-color: $st-gold_01h;
                }
            }
            span {
                color: $st-gold_01h;
            }
        }
        // En proceso
        &.en-proceso {
            .circle {
                border-color: $st-green_03;
                div {
                    background-color: $st-green_02;
                }
            }
            span {
                color: $st-green_02;
            }
        }
        // Completado
        &.completado {
            .circle {
                border-color: $st-gray_03;
                div {
                    background-color: $st-gray_07;
                }
            }
            span {
                color: $st-gray_07;
            }
        }
    }

    .vertical-line {
        width: 2px;
        height: 20px;
        background-color: $st-gray_02;
        text-align: center;
        margin: 0 0 0 15px;
    }
}

// Select file

.cont-archivo {
    color: $st-black_01;
    font-weight: 400;
    line-height: normal;
    background-color: $st-gold_02;
    padding: 1rem;
    .archivo {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $st-gold_03;
        margin: 0 0 0.5rem;
        padding: 1rem;
        border-radius: 6px;
        span {
            font-size: $fs-15;
            margin: 0 0 0 0.5rem;
        }
        .cont-actions {
            display: flex;
            align-items: center;
            justify-content: center;
            div {
                color: $st-green_01;
                font-weight: 600;
            }
            .cancel {
                cursor: pointer;
            }
        }
    }
    span {
        font-size: $fs-12;
    }
}

/** Modal **/

#setActiveUser,
#modalCierreOperacion,
#modalCustodioConfirm,
#nopuedeeliminar,
#nohaydisponibilidad,
#yaexisteocupacion {
    h1 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 900;
        font-size: 1.7em;
        line-height: 34px;
        text-align: center;
        color: $verde03;
    }

    h2 {
        font-weight: 700;
        font-size: 1.2em;
        color: $verde03;
    }

    .modal-content {
        border-color: $verde03;
        border-width: 3px;
    }
}

/** Accordion **/

.st-accordion {
    .card {
        border-radius: 0;
        border-color: $st-black_05h;
        .card-header {
            padding: 0;
            border: none;
            border-radius: 0px;
            button {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: $st-green_02;
                font-size: 1rem;
                font-weight: 800;
                line-height: 1rem;
                background-color: $st-black_05;
                border-radius: 0px;
                &:hover {
                    background-color: $st-black_05h;
                }
            }
        }
        .card-body {
            background-color: $st-gold_04;
        }
    }
}
