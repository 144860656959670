body {
    font-family: "Inter", sans-serif;
    min-height: 100vh;
    #app {
        background-color: $st-gray_04;
        header {
        }
        main {
            background-color: $st-gray_04;
        }
    }
}
