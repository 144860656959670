#welcomebg {
    background-image: url("../images/cement2.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 150px;
}

.card-welcome {
    border-radius: 10px 10px 10px 10px;
}

.card-header {
    &.hh {
        border-radius: 10px 10px 0px 0px !important;
        color: $white;
        background-color: $dorado03;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        h1 {
            font-weight: 900;
            font-size: 24px;
        }
    }
}

.welcome {
    h1 {
        color: $guinda03;
        font-family: Inter;
        font-weight: 700;
        font-size: 30px;
    }
}

.card-actions {
    &.bg-actions {
        background-color: $white-two;
    }

    .action {
        background-color: $dorado03;
        color: $white;
        font-family: Inter;
        font-weight: 700;
        border-radius: 8px 8px 8px 8px;
        padding-top: 0.35rem;
        padding-bottom: 0.35rem;
        line-height: 13px;
        display: flex;
        align-items: center;

        .text-mini {
            font-size: 10px;
            line-height: 11px;
        }

        img {
            width: 30px;
            height: 30px;
            float: left;
        }

        a {
            display: flex;
            align-items: center;
            color: $white-two;
            text-decoration: none;
        }

        a:hover {
            text-decoration: none;
        }
    }

    h2 {
        color: $dorado03;
        font-size: 24px;
        font-weight: 700;
        background-color: $very-light-pink-five;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 0px;
    }

    h3 {
        color: $dorado03;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
    }

    .left-menu {
        border-radius: 30px;
        box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.25);
        background-color: $gray-03;
    }

    .right-menu {
        border-top-left-radius: 0px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 0px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        background-color: $gray-03;
    }
}

.policy-modal-body {
    display: block;
    position: relative;
    font-size: $fs-16;
    margin: 0;
    padding: 0.15rem 0 0.15rem 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked {
            & ~ .checkmark {
                background-color: $st-green_02;
                &:after {
                    display: block;
                }
            }
        }
    }
    .checkbox {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: $st-gray_02;
        border-radius: 5px;
        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 9.5px;
            top: 4px;
            width: 6px;
            height: 13px;
            border: solid white;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
        }
    }
    .radio {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: $st-gray_02;
        border-radius: 50%;
        &:after {
            content: "";
            position: absolute;
            display: none;
            top: 7px;
            left: 8px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: white;
        }
    }
    &:hover {
        .checkmark {
            background-color: $st-gray_04;
        }
    }

    a{
        color: $guinda01;
    }
}
