section#home {
    background-color: $st-gray_04;
    .encabezado {
        .title {
            background-color: $st-gold_01;
            padding: 1.25rem;
            border-radius: 10px 10px 0px 0px;
            h1 {
                color: white;
                font-size: 1.5rem;
                font-weight: 800;
                line-height: 1.55rem;
                text-align: center;
                margin: 0;
            }
        }
        .subtitle {
            position: relative;
            z-index: 10;
            background-color: $st-gray_10;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
            padding: 1rem;
            h3 {
                color: $st-gold_01;
                font-size: 1.5rem;
                font-weight: 700;
                line-height: 1.55rem;
                text-align: center;
                margin: 0;
            }
        }
    }
    .cont-menu {
        background-color: white;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        .menu {
            justify-content: center;
            &.admin {
                .item {
                    background: linear-gradient(
                        94.61deg,
                        rgba(228, 228, 228, 0.3) 28.24%,
                        rgba(255, 255, 255, 0) 189.6%
                    );
                }
            }
            &:not(.admin) {
                background: linear-gradient(94.61deg, rgba(228, 228, 228, 0.3) 28.24%, rgba(255, 255, 255, 0) 189.6%);
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                backdrop-filter: blur(35.7855px);
                border-radius: 30px;
            }
            .item {
                h2 {
                    color: $st-gold_01;
                    font-size: 1.5rem;
                    font-weight: 700;
                    line-height: 1.55rem;
                    text-align: center;
                    margin: 0 0 2rem;
                }
                &.left {
                    box-shadow: 4px -1px 10px rgba(0, 0, 0, 0.25);
                    border-radius: 30px;
                }
                .cont-actions {
                    .action {
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        max-width: 255px;
                        background-color: $st-gold_01;
                        border-radius: 8px;
                        padding: 0.4rem 1.5rem;
                        margin: 0 auto 0.85rem;
                        cursor: pointer;
                        &:hover {
                            background-color: $st-gold_01h;
                        }
                        img {
                            max-width: 30px;
                            height: 30px;
                        }
                        span {
                            color: white;
                            font-weight: 500;
                            font-size: 0.938rem;
                            line-height: 0.95rem;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
