header {
	border-bottom: 5px solid $gray-01;
	padding: 0em 0rem 0rem;
	nav {
		margin: 0 auto;
		max-width: 6000px;
	}
}

// footer {
//     font-family: "Inter", sans-serif;
//     background-color: $verde05;
//     letter-spacing: 0.5px;
//     img {
//         width: 100%;
//     }
//     .container {
//         h5 {
//             color: white;
//             font-weight: 700;
//             font-size: $fs-16;
//             line-height: 1.4;
//         }
//         p {
//             color: white;
//             font-size: $fs-14;
//             font-weight: bold;
//         }
//         a {
//             color: white;
//             font-weight: 500;
//             line-height: normal;
//             &:hover {
//                 color: $verde01;
//                 text-decoration: none;
//             }
//         }
//         .enlaces {
//             border-top: 1px solid $verde04;
//             ul {
//                 li {
//                     list-style: none;
//                 }
//             }
//         }
//     }
// }

// Estilos
.app-title {
    height: 34.6px;
    font-family: "Gotham Bold" !important;
    font-size: 9px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: $bluish-grey;
    text-transform: uppercase;
    padding: 5px 10px;
    margin: 5px;
}

@media only screen and (max-width: 585px) {
    .auto-hide {
        display: none;
    }
}

.linea {
    width: 0.3px !important;
    height: 34.6px;
    border-right: solid 2px $bluish-grey;
    margin: 5px !important;
}

.brand-logo-cdmx {
    max-width: 100%;
    height: auto;
}

.tx-crea-cuenta {
    width: 100%;
    font-family: "Metropolis";
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $brownish-grey;
}

.tx-crea-cuenta a {
    color: $shamrock;
}

#nav-secciones {
    background-color: $gray-03;
    padding-top: 10px;
    padding-bottom: 10px;
}

.nb-usuario {
    color: $guinda03;
    height: 20px;
    font-size: 13px;
    font-weight:700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: right;
    text-transform: capitalize !important;
  }

.user-ico {
    padding-right: 5px;
}

.tp-user {
    font-family: Metropolis;
    font-size: 11px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: 0.18px;
    text-align: right;
    color: $pine-green;
    padding-right: 30px;
}

.llave-logo{
    max-width: 183px;
    height: auto;
}