form.needs-validation {
    input[type="text"], input[type="date"], input[type="number"]{
        &.st-input:read-only{
            border: 0px !important;
            background-color: transparent;
            font-weight: 700 !important;
            color: $black-all !important;            
        }
    }
    .st-input:disabled {
        border: 0px !important;
        background-color: transparent;
        font-weight: 700 !important;
        color: $black-all !important;
    }
    &.was-validated {
        .form-control:invalid {
            border-color: $red !important;
            box-shadow: 0 0 0 0.2rem rgb(227 52 47 / 25%);
        }
        .form-control:valid {
            // border-color: $green !important;
            // box-shadow: 0 0 0 0.2rem rgb(56 193 114 / 25%);
        }
    }
    .invalid-feedback {
        font-size: small;
    }
}

form.form-validation-supervision {
    .st-input:disabled {
        border: 0px !important;
        background-color: transparent;
        font-weight: 700 !important;
        color: $black-all !important;
    }
    &.was-validated {
        .form-control:invalid {
            border-color: $red !important;
            box-shadow: 0 0 0 0.2rem rgb(227 52 47 / 25%);
        }
        .form-control:valid {
            // border-color: $green !important;
            // box-shadow: 0 0 0 0.2rem rgb(56 193 114 / 25%);
        }
    }
    .invalid-feedback {
        font-size: small;
    }
}

form.form-validation-incidencia {
    .st-input:disabled {
        border: 0px !important;
        background-color: transparent;
        font-weight: 700 !important;
        color: $black-all !important;
    }
    &.was-validated {
        .form-control:invalid {
            border-color: $red !important;
            box-shadow: 0 0 0 0.2rem rgb(227 52 47 / 25%);
        }
        .form-control:valid {
            // border-color: $green !important;
            // box-shadow: 0 0 0 0.2rem rgb(56 193 114 / 25%);
        }
    }
    .invalid-feedback {
        font-size: small;
    }
}

#frmServicioFune,
#frmDetalleServicio {
    .st-label {
        &.elcheck {
            padding: 0.1rem 0 0.1rem 30px;
            span {
                left: 10px;
            }
        }
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked {
                & ~ .checkmark {
                    background-color: $dorado01;
                    &:after {
                        display: block;
                    }
                }
            }
        }
        .checkbox {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: $st-gray_02;
            border-radius: 5px;
            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 9.5px;
                top: 4px;
                width: 6px;
                height: 13px;
                border: solid white;
                border-width: 0 3px 3px 0;
                transform: rotate(45deg);
            }
        }
        &:hover {
            .checkmark {
                background-color: $st-gray_04;
            }
        }
    }
    .secc-otros {
        &.elcheck {
            padding: 0.1rem 0 0.1rem 30px;
            span {
                left: 10px;
            }
        }
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked {
                & ~ .checkmark {
                    background-color: $dorado01;
                    &:after {
                        display: block;
                    }
                }
            }
        }
        .checkbox {
            position: absolute;
            // top: 0;
            // left: 0;
            height: 25px;
            width: 25px;
            background-color: $st-gray_02;
            border-radius: 5px;
            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 9.5px;
                top: 4px;
                width: 6px;
                height: 13px;
                border: solid white;
                border-width: 0 3px 3px 0;
                transform: rotate(45deg);
            }
        }
        &:hover {
            .checkmark {
                background-color: $st-gray_04;
            }
        }
    }
}

#otros-container {
    .btn-menos {
        cursor: pointer;
        color: $st-gold_01;
        background-color: white;
        border: 1px solid $st-gold_01;
    }

    .st-input:disabled {
        border: 1px solid !important;
        background-color: $st-gray-08;
        font-weight: normal !important;
        color: $st-gray-09 !important;
    }
}
#add-otro-container {
    .btn-mas {
        cursor: pointer;
        border: 1px solid $st-gold_01;
    }

    .st-input:disabled {
        border: 1px solid !important;
        background-color: $st-gray-08;
        font-weight: normal !important;
        color: $st-gray-09 !important;
    }
}

.mailto {
    text-decoration: underline;
}
