/**
* CSS Arquetipo
*/

// Fuentes
@font-face {
    font-family: Metropolis;
    src: url("../fuentecillas/Metropolis-Regular.otf") format("opentype");
}

@font-face {
    font-family: "Gotham Bold";
    src: url("../fuentecillas/Gotham-Bold_0.otf") format("opentype");
}

strong {
    font-weight: bolder;
}

.dropdown-item {
    font-size: 12px;
}
.dropdown-item:hover {
    color: $white-two;
    background-color: $guinda02;
}
.dropdown-item:focus {
    color: $white-two;
    background-color: $dark-green;
}

.navbar-light .navbar-nav .nav-link {
    color: $pine-green !important;
}

.flex-fill {
    flex: 1 1 auto !important;
}

.plecota {
    margin-top: 0px;
    padding-top: 10px;
    background-color: $pine-green;
    padding-bottom: 10px;
    position: relative;
    background-image: url("../images/pleca.svg");
    background-repeat: repeat-y;
    background-position-x: right;
}

.encabezado-cdmx {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $shamrock;
    padding-bottom: 20px;
}

.error-title {
    font-size: 20px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $guinda01;
    padding-bottom: 20px;
}

.instrucciones-pleca {
    color: $white-two;
    font-size: 14px;
}

.page-link {
    border-width: 0px !important;
    border: none !important;
    color: $pine !important;
}

.curpage {
    color: #00b140 !important;
    font-weight: bold;
}

@media only screen and (max-width: 700px) {
    .alertaActivacion {
        position: fixed !important;
        top: 5px;
        right: 2%;
        width: 97%;
        z-index: 9999;
    }
}

@media only screen and (min-width: 700px) {
    .alertaActivacion {
        position: fixed !important;
        top: 5px;
        right: 2%;
        width: 50%;
        z-index: 9999;
    }
}

.lbl-dialog {
    font-family: Metropolis;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #5d5d5d;
}

.adip-loader-gif {
    min-width: 64px;
    max-width: 64px;
    min-height: 64px;
    max-height: 64px;
}

.jeader-title {
    height: 34.6px;
    font-family: "Gotham Bold" !important;
    font-size: 9px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: $guinda03;
    text-transform: uppercase;
    padding: 5px 10px;
    margin: 5px;
}

.linea {
    width: 0.3px !important;
    height: 34.6px;
    border-right: solid 2px $guinda03;
    margin: 5px !important;
}

.myaccount {
    color: $st-gray_09;
    font-size: 13px;
    font-weight: 700;
}

.dropdown-toggle:after {
    display: none !important;
}

.link-header {
    font-size: 13px;
    color: $verde06 !important;
    font-weight: 700;
    text-decoration: underline;
    font-family: Inter;
}
.link-header:hover {
    text-decoration: underline;
}

footer {
    //font-family: "Inter", sans-serif;
    background-color: $verde05;
    letter-spacing: 0.5px;
    img {
        width: 100%;
    }
    .container-fluid {
        h5 {
            color: white;
            font-weight: 700;
            font-size: $fs-16;
            line-height: 1.4;
        }
        p {
            color: white;
            font-size: $fs-14;
        }
        a {
            color: white;
            font-weight: 500;
            line-height: normal;
            &:hover {
                color: $verde04;
                text-decoration: none;
            }
        }
        .enlaces {
            border-top: 1px solid $verde04;
            ul {
                li {
                    list-style: none;
                }
            }
        }
        ul.subrayada {
            a {
                text-decoration: underline !important;
            }
        }
    }
    .greca-footer {
        max-height: 36px;
        min-height: 36px;
        background-image: url("../images/greca.png");
        background-repeat: repeat-x;
    }
    .redes {
        img {
            max-width: 24px;
            max-height: 24px;
            margin: 3px;
        }
    }
}
