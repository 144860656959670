.tabla{
        width: 100%;

	thead {
	  background-color: $st-gold_01;

	  width: 100%;
	  text-align: center;


	  th{
	    padding: 0.625rem;
	    font-family: 'Inter';
	    font-style: normal;
	    font-size: 13px;
	    color: white;
	  }
	}

	tbody{
		tr{
			text-align: center;
			border-bottom: solid .0625rem $gray-05;
			background: $st-gold_04;
			td {
				    font-family: 'Inter';
				    font-style: normal;
				    font-weight: 400;
				    font-size: 13px;
				    text-align: center;
				    color: $st-black_01;
			   }
		}
        }

		tr:first-child th:first-child{
		  border-top-left-radius: 7px;
		}
		tr:first-child th:last-child{
		  border-top-right-radius: 7px;
		}
}
