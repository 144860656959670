form,
.content-form {
    .title {
        width: 100%;
        color: white;
        font-size: $fs-22;
        font-weight: 700;
        line-height: $fs-22;
        background-color: $st-gold_01;
        margin: 0;
        padding: 0.75rem 1.25rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px 10px 0px 0px;
    }
    .subtitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 2rem;
        h2 {
            width: 100%;
            color: $st-green_02;
            font-size: $fs-25;
            font-weight: 700;
            line-height: $fs-24;
            text-align: center;
            margin: 0;
            div {
                font-size: $fs-16;
                margin-top: 0.5rem;
            }
        }
        h3 {
            color: $st-gold_01;
            font-size: $fs-20;
            font-weight: 700;
            line-height: $fs-20;
            margin: 0;
        }
        h4 {
            width: 100%;
            color: $st-green_02;
            font-size: $fs-20;
            font-weight: 700;
            line-height: $fs-20;
            text-align: center;
            margin: 0;
        }
        span {
            float: right;
            color: $st-red_05;
            font-size: $fs-14;
            font-weight: 500;
            line-height: $fs-14;
        }
        p {
            color: $st-black_02;
            font-size: $fs-15;
            font-weight: 400;
            line-height: $fs-15;
            margin: 1rem 0 0;
        }
    }
    .fields {
        background-color: white;
        padding: 1.5rem;
        .form-group {
            padding-bottom: 0.75rem;
        }
        .form-btns {
            text-align: right;
        }
        .st-label {
            display: block;
            color: $st-gray_07;
            font-size: $fs-14;
            font-weight: 600;
            line-height: normal;
            margin: 0 0 0.75rem;
            cursor: pointer;
            &.lg {
                font-size: $fs-15;
            }
            b {
                color: $st-red_05;
            }
        }
        .not-required {
            .st-label {
                b {
                    display: none;
                }
            }
        }
        .st-input {
            display: block;
            width: 100%;
            height: auto;
            // max-width: 300px;
            max-width: 510px;
            color: $st-gray_07;
            font-size: $fs-15;
            font-weight: 400;
            line-height: normal;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0.45rem 0.6rem;
            border: 1px solid $st-gray_05;
            border-radius: 4px;
        }
        select.st-input {
            background-image: linear-gradient(45deg, transparent 50%, white 50%),
                linear-gradient(135deg, white 50%, transparent 50%), linear-gradient(to right, $st-gold_01, $st-gold_01);
            background-position: calc(100% - 19px) calc(13px), calc(100% - 12px) calc(13px), 100% 0;
            background-size: 7px 8px, 8px 7px, 40px 50px;
            background-repeat: no-repeat;
            -webkit-appearance: none;
            -moz-appearance: none;
            padding-right: 3rem;
            cursor: pointer;
            &:hover {
                background-image: linear-gradient(45deg, transparent 50%, white 50%),
                    linear-gradient(135deg, white 50%, transparent 50%),
                    linear-gradient(to right, $st-gold_01h, $st-gold_01h);
            }
        }

        /* Input: Checkbox / Radio */
        .st-radio,
        .st-check {
            &:not(.d-flex) {
                label:not(:last-child) {
                    margin: 0 0 0.5rem;
                }
            }
        }

        .cont-input {
            display: flex;
            align-items: center;
            justify-content: start;
            cursor: pointer;
            input {
                width: 20px;
                height: 20px;
                accent-color: $st-gold_01h;
                margin: 0 0.5rem 0 0;
                cursor: pointer;
            }
            span {
                color: $st-gray_07;
                font-size: $fs-14;
                font-weight: 600;
                line-height: normal;
            }
            &:hover {
                input {
                    accent-color: $st-gold_01h;
                }
                span {
                    color: $st-gold_01h;
                }
            }
        }
    }
}

#get-cementerios {
    .fields {
        border-radius: 10px;
        input.custom-btn {
            width: 100%;
            display: inline-block;
            height: fit-content;
            align-self: end;
            background-color: $st-green_01;
            color: white;
            font-size: $fs-14;
            font-weight: 600;
            letter-spacing: 0.035rem;
            line-height: $fs-14;
            padding: 0.5rem 1.25rem;
            border: 2px solid transparent;
            border-radius: 6px;
            &:hover {
                background-color: $st-green_01h;
            }
            &:disabled {
                background-color: $st-gray_06;
            }
        }
    }
}

#form-modalidad .fields {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
