.st-btn {
    display: inline-block;
    height: fit-content;
    font-size: $fs-14;
    font-weight: 600;
    letter-spacing: 0.035rem;
    line-height: $fs-14;
    padding: 0.75rem 1.25rem;
    border: 2px solid transparent;
    border-radius: 6px;
    // &.add {
    //     font-size: $fs-15;
    //     font-weight: 800;
    //     line-height: 18px;
    //     padding: 0.6rem 0.7rem;
    //     border-radius: 15px;
    // }
    // &.submit {
    //     font-size: $fs-17;
    //     line-height: 24px;
    //     letter-spacing: -0.01em;
    //     padding: 0.6rem 1rem;
    //     img {
    //         margin-top: -3px;
    //     }
    // }
    &.cancel {
        color: $st-green_01;
        background-color: white;
        border-color: $st-green_01;
        &:hover {
            color: $st-red_04;
            background-color: $st-red_01;
            border-color: $st-red_02;
        }
    }
    &.green {
        color: white;
        background-color: $st-green_01;
        &:hover {
            background-color: $st-green_01h;
        }
    }
    &.blue {
        color: white;
        background-color: $st-blue_04;
        &:hover {
            background-color: $st-blue_04h;
        }
    }
    &.disabled {
        color: white;
        background-color: $st-gray_07;
        pointer-events: none;
        &:hover {
            background-color: $st-gray_09;
        }
    }
    &:disabled {
        background-color: $st-gray_06;
    }
    // &.guinda {
    //     color: white;
    //     background-color: $st-guinda_02;
    //     &:hover {
    //         background-color: $st-guinda_03;
    //     }
    // }
    // &.gray {
    //     color: white;
    //     background-color: $st-gray_06;
    //     &:hover {
    //         background-color: $st-gray_07;
    //     }
    // }
    // &.gold {
    //     color: white;
    //     background-color: $st-gold_01;
    //     &:hover {
    //         background-color: $st-gold_03;
    //     }
    // }
    // &.red {
    //     color: white !important;
    //     background-color: $st-red_03;
    //     &:hover {
    //         background-color: $st-red_04;
    //     }
    // }
    // &.gray {
    //     color: $st-black_01;
    //     background-color: $st-gray_06;
    //     &:hover {
    //         background-color: $st-gray_07;
    //     }
    // }
    // &.white {
    //     color: $st-black_01;
    //     background-color: white;
    //     &:hover {
    //         color: $st-red_03;
    //         background-color: $st-red_05;
    //     }
    // }
    // &:hover {
    //     text-decoration: none;
    // }
    // &:focus {
    //     outline: none;
    // }
    // &:disabled {
    //     color: $st-gray_07;
    //     background-color: $st-gray_02;
    //     pointer-events: none !important;
    // }
    // &.lens {
    //     font-size: 0.95rem;
    //     padding: 0.4rem 0.8rem;
    // }
    &:hover {
        text-decoration: none;
    }
    img {
        margin-right: 0.25rem;
        transform: scale(0.75);
    }
}

.st-btn-sm {
    display: inline-block;
    height: fit-content;
    font-weight: 600;
    letter-spacing: 0.035rem;
    line-height: $fs-14;
    border: 2px solid transparent;
    border-radius: 6px;

    &.cancel {
        color: $st-green_01;
        background-color: white;
        border-color: $st-green_01;
        &:hover {
            color: $st-red_04;
            background-color: $st-red_01;
            border-color: $st-red_02;
        }
    }
    &.green {
        color: white;
        background-color: $st-green_01;
        &:hover {
            background-color: $st-green_01h;
        }
    }
    &.guinda {
        color: white;
        background-color: $st-red_06;
        &:hover {
            background-color: $st-red_05;
        }
    }
    &:disabled {
        background-color: $st-gray_06;
    }

    &:hover {
        text-decoration: none;
    }
    img {
        margin-right: 0.25rem;
        transform: scale(0.75);
    }
}
