#accVisitas > .card {
    border: 1px solid #fff;
    .card-body{
        background-color: #F8F4EF;
    }
}
#accSeguimientos > .card {
    border: 1px solid #fff;
    .card-body{
        background-color: #F8F4EF;
    }
}
.card-header-cdmx{
    background-color: rgba(188, 149, 92, 0.1);
    padding: 0.25rem 1.25rem;
    border-bottom: 2px solid rgb(255, 255, 255);
    .text-link-collapse {
        color: #0F4C42;
        font-weight: bold;
    }
    .icon-link-collapse{
        text-decoration: none;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: 2px solid #0F4C42;
        color: #0F4C42;
        display: block;
        float: right;
        text-align: center;
    }
    .icon-link-collapse:after {
        color: #0F4C42;
        content: "-";
        position:relative;
        font-size:19px;
        line-height: normal;
        font-weight:600;
    }
    .icon-link-collapse.collapsed:after {
        color: #0F4C42;
        content: "+";
    } 
}