.for-livewire {
    .pagination {
        .page-item {
            button {
                font-size: 0.9rem;
                font-weight: normal;
                letter-spacing: normal;
            }
            &.active {
                .page-link {
                    color: $green-01 !important;
                    font-weight: bold;
                    background-color: transparent;
                    cursor: default;
                }
            }
        }
    }
}
