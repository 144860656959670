#nuevoCementerio {
}

.texto_cdmx {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0px;
    width: 100%;
    text-align: left;
    line-height: 29px;
}

.label_modalidad {
    padding-left: 40px !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #6f7271;
}

.custom-control-input-cdmx:checked ~ .custom-control-label-cdmx::after {
    background-color: #bc955c;
    background-image: none !important;
    border-color: none !important;
    border-radius: 50% !important;
}

.btn {
    &.btn-pine-gree {
        display: inline-block;
        height: fit-content;
        font-size: $fs-14;
        font-weight: 600;
        letter-spacing: 0.035rem;
        line-height: $fs-14;
        padding: 0.75rem 1.25rem;
        border: 2px solid transparent;
        border-radius: 6px;
        color: white;
        background-color: $st-green_01;
        &:hover {
            background-color: $st-green_01h;
        }
    }
}

.btn-cancelar {
    color: #0f4c42;
    border-color: #0f4c42 !important;
    background: white;
}

.btn-prestador-servicios {
    line-height: normal !important;
    margin: 0px !important;
    padding: 2px 22px !important;
}

.div-submenu {
    background: #ffffff;
    border-radius: 10px;
}

.custom-control-label-submenu::after {
    position: absolute;
    top: 0.22rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%;
}

.custom-control-label-submenu::before {
    position: absolute;
    top: 0.22rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%;
    color: #235b4e;
}

.custom-control-input-submenu {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.22rem;
    opacity: 0;
}

.custom-control-input-submenu:checked ~ .custom-control-label-submenu::after {
    background-color: #235b4e;
    background-image: none !important;
    border-color: none !important;
    border-radius: 50% !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #235b4e;
}

.texto-verde {
    color: #235b4e;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.btn-mas,
.btn-menos {
    background-color: $st-gold_01;
    color: white;
    width: 33px;
    cursor: pointer;
    &:hover {
        background-color: $st-gold_01h;
    }
}

.areaobli {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #e3342f;
}

.rounded-modal {
    border-radius: 1rem !important;
    overflow: hidden;
}

.registroexitoso {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    font-size: 27.9914px;
    line-height: 34px;
    text-align: center;
    color: #0f4c42;
}

.registroexitoso-lg {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: normal;
    text-align: center;
    color: #0f4c42;
}

.error_modalidad,
.error_horario,
.yaexistelugar,
.yaexisteocupacion,
.nopuedeeliminar,
.nohaydisponibilidad,
.s_cementerioobli,
.yaEstaAsignadoCementerio,
.error_clasificacion,
.error_asignacementerio,
.errorArchivo,
.invalid-feedback,
.error_notifica,
.error_lugardesignado,
.senecesitanotificar,
.error_registroTipoincidencia,
.error_tipoIncidenciaDuplicada,
.s_incidenciaobli,
.errorfechamayor,
.observacionobl,
.observacionobli,
.errorfechavisita,
.error_agregararea,
.error_distribucionespacios {
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: $red;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
}

#prestadoresbandeja {
    .card {
        border: none;
    }
}

.titbandeja {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #235b4e !important;
    background-color: white;
    border: none;
}

.textoseguimiento {
    background: transparent !important;
    border: none;
}

.fa-minus-circle:before,
.fa-plus-circle:before {
    color: #235b4e;
}

.switcher {
    display: inline-block;
}

.switcher label {
    display: block;
    margin: 0;
    width: 46px;
    height: 28px;
    position: relative;
    cursor: pointer;
}

.switcher label:before {
    content: "";
    display: block;
    width: 46px;
    height: 28px;
    background: #d5dbe0;
    border: 1px solid #d5dbe0;
    -webkit-border-radius: 46px;
    border-radius: 46px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.switcher label:after {
    content: "";
    height: 24px;
    width: 24px;
    position: absolute;
    left: 2px;
    top: 2px;
    background: #fff;
    -webkit-border-radius: 24px;
    border-radius: 24px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.switcher input {
    display: none;
}

.switcher input:checked + label:before {
    background: #bc955c;
    border: 2px solid #bc955c;
}

.switcher input:checked + label:after {
    left: 20px;
}
